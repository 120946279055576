<template>
    <div
        v-if="intranet"
        class="page admin"
    >
        <div class="container padded">
            <h1>{{ $t("title_administration") }}</h1>
            <Tabs
                :links="tabs"
            />
            <transition
                name="fade-fast"
                mode="out-in"
            >
                <keep-alive>
                    <router-view class="pbottom-large"/>
                </keep-alive>
            </transition>
        </div>
    </div>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import Tabs from "@web/components/Tabs.vue";
import { mapGetters } from "vuex";

export default {
    name: "Admin",
    components: {
        Tabs,
    },
    data() {
        return {
            tabs: [
                { to: "general", title: this.$t("general") },
                { to: "theme", title: this.$t("theme") },
                { to: "launchpad", title: this.$t("launchpad") },
                { to: "user-access", title: this.$t("admin_user_access_title") },
                { to: "features", title: this.$t("admin_features_title") },
                { to: "admin-integrations", title: this.$t("integrations") },
                { to: "workspace", title: this.$t("workspace_and_subscription") },
            ],
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
    },
};
</script>

<style lang="scss" scoped>
.admin {
    &::v-deep {
        .intro {
            max-width: 500px;
            padding: 0 0 2rem 0;
            color: #666;
        }
    }
}
</style>
